import classNames from 'classnames'
import { ElementType, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FileInput,
  Input,
  InputAttributes,
  MultiSelect,
  MultiSelectProps,
  RadioButton,
  RichSelect,
  RichSelectOptionType,
  RichSelectProps,
  Select,
  SelectAttributes,
  TextArea,
} from 'src/components/Elements'
import { ComponentWithAs } from 'src/components/type-utils'

import { ReadOnlySelectFormField } from './FormFields'

export interface InputFieldProps extends Omit<InputAttributes, 'isRequired' | 'prefix'> {
  label?: string
  sideNote?: string
  className?: string
  name?: string
  isRequired?: boolean
  prefix?: string
  suffix?: string
}

export const InputField = ({
  className,
  label,
  sideNote,
  prefix,
  suffix,
  name,
  isRequired = false,
  children,
  ...props
}: InputFieldProps) => (
  <div className={className}>
    {label && (
      <FieldLabel name={name} isRequired={isRequired}>
        {label}
      </FieldLabel>
    )}
    {sideNote && <p className="inline text-sm italic">{sideNote}</p>}
    <div className="w-full flex items-center space-x-2">
      {prefix && <div className="flex-shrink whitespace-nowrap">{prefix}</div>}
      <div className="w-full">
        <Input required={isRequired} id={name} {...props} />
      </div>
      {suffix && <div className="flex-shrink whitespace-nowrap align-middle">{suffix}</div>}
    </div>
    {children}
  </div>
)

export const FileInputField = ({
  className,
  label,
  sideNote,
  prefix,
  suffix,
  name,
  isRequired = false,
  children,
  ...props
}: Omit<InputFieldProps, 'type'>) => (
  <div className={className}>
    {label && (
      <FieldLabel name={name} isRequired={isRequired}>
        {label}
      </FieldLabel>
    )}
    {sideNote && <p className="inline text-sm italic">{sideNote}</p>}
    <div className="w-full flex items-center space-x-2">
      {prefix && <div className="flex-shrink whitespace-nowrap">{prefix}</div>}
      <div className="w-full">
        <FileInput required={isRequired} id={name} type="file" {...props} className="w-full" />
      </div>
      {suffix && <div className="flex-shrink whitespace-nowrap align-middle">{suffix}</div>}
    </div>
    {children}
  </div>
)

export interface SelectFieldProps extends Omit<SelectAttributes, 'isRequired'> {
  label?: string
  options?: { id: string; name: string }[]
  className?: string
  name?: string
  isRequired?: boolean
  isChanged?: boolean
  strikeThrough?: boolean
}

export const SelectField = ({
  label = '',
  placeholder = undefined,
  isRequired = false,
  options = [],
  className = undefined,
  children = undefined,
  name = undefined,
  ...props
}: SelectFieldProps) => {
  const { t } = useTranslation()
  const translatedPlaceholder = placeholder ?? t('Select')
  return !props.readOnly ? (
    <div className={className}>
      {label && (
        <FieldLabel name={name} isRequired={isRequired}>
          {label}
        </FieldLabel>
      )}
      <Select
        className="w-full"
        name={name}
        id={name}
        isRequired={isRequired}
        placeholder={translatedPlaceholder}
        options={options}
        {...props}
      />
      {children}
    </div>
  ) : (
    <ReadOnlySelectFormField
      label={label}
      value={props.value}
      options={options}
      className={className}
      strikeThrough={props.strikeThrough}
    />
  )
}

export interface MultiSelectFieldProps extends MultiSelectProps {
  label?: string
  className?: string
  isRequired?: boolean
  isChanged?: boolean
  children?: ReactNode
}

export const MultiSelectField = ({
  name,
  label = undefined,
  isRequired = false,
  placeholder = undefined,
  className = undefined,
  children,
  ...props
}: MultiSelectFieldProps) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      {label && (
        <FieldLabel name={name} isRequired={isRequired}>
          {label}
        </FieldLabel>
      )}
      <MultiSelect
        className="w-full"
        {...props}
        placeholder={placeholder ?? t('Select')}
        id={name}
        isRequired={isRequired}
      />
      {children}
    </div>
  )
}

export interface RichSelectFieldProps<T extends RichSelectOptionType> extends RichSelectProps<T> {
  label?: string
  className?: string
  controlClassName?: string
  isRequired?: boolean
  isChanged?: boolean
  children?: ReactNode
}

interface RichSelectFieldComponent {
  <T extends RichSelectOptionType>(props: RichSelectFieldProps<T>): JSX.Element
}

export const RichSelectField: RichSelectFieldComponent = ({
  name,
  label = undefined,
  isRequired = false,
  isSearchable = false,
  placeholder = undefined,
  className = undefined,
  controlClassName = undefined,
  children,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      {label && (
        <FieldLabel name={name} isRequired={isRequired}>
          {label}
        </FieldLabel>
      )}
      <RichSelect
        className="w-full h-full"
        {...props}
        placeholder={placeholder ?? (isSearchable ? t('Type to search') : t('Select'))}
        id={name}
        isRequired={isRequired}
        isSearchable={isSearchable}
      />
      {children}
    </div>
  )
}

export const TextAreaField = ({
  className = '',
  name = undefined,
  label = '',
  isRequired = false,
  isChanged = false,
  hasError = false,
  placeholder = '',
  children = null,
  ...props
}) => (
  <div className={className}>
    {label && (
      <FieldLabel name={name} isRequired={isRequired}>
        {label}
      </FieldLabel>
    )}
    <TextArea placeholder={placeholder} {...props} required={isRequired} id={name} />
    {children}
  </div>
)

export const FieldLabel = ({
  name,
  children,
  isRequired,
  className = '',
}: {
  name?: string
  children: any
  isRequired?: boolean
  className?: string
}) => (
  <label
    htmlFor={name}
    className={classNames('inline-block uppercase text-xs tracking-wide leading-tight font-semibold p-2', className)}
  >
    {children}
    {isRequired ? <span className="text-red-700">*</span> : ''}
  </label>
)

export const BigFieldLabel = ({
  name,
  children,
  isRequired,
  className = '',
}: {
  name?: string
  children: any
  isRequired?: boolean
  className?: string
}) => (
  <label htmlFor={name} className={classNames('leading-tight p-2', className)}>
    {children}
    {isRequired ? <span className="text-red-700">*</span> : ''}
  </label>
)

export const HugeFieldLabel = ({
  name,
  children,
  isRequired,
  className = '',
}: {
  name?: string
  children: any
  isRequired?: boolean
  className?: string
}) => (
  <label htmlFor={name} className={classNames('leading-tight font-semibold p-2 text-lg', className)}>
    {children}
    {isRequired ? <span className="text-red-700">*</span> : ''}
  </label>
)

export const MediumFieldLabel = ({
  name,
  children,
  isRequired,
  className = '',
}: {
  name?: string
  children: any
  isRequired?: boolean
  className?: string
}) => (
  <label
    htmlFor={name}
    className={classNames(
      'text-sm tracking-wide font-semibold uppercase text-slate-400 px-2 py-4 print:py-2',
      className,
    )}
  >
    {children}
    {isRequired ? <span className="text-red-700">*</span> : ''}
  </label>
)

interface ReadOnlyFieldProps {
  className?: string
  label?: string
  children: ReactNode
}

export const ReadOnlyField: ComponentWithAs<ReadOnlyFieldProps> = ({
  className = '',
  label = undefined,
  children,
  value = null,
  as: Element = 'div',
}: {
  className?: string
  label?: ReactNode
  children?: ReactNode
  value?: any
  as?: ElementType
}) => (
  <Element className={className}>
    {label ? <span className="font-semibold">{label}: </span> : null}
    {value}
    {children}
  </Element>
)

export const YesNoToggleField = ({
  question,
  setValue,
  value,
}: {
  question: string
  setValue: (value) => void
  value: boolean
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex space-x-2 items-center">
      <label className="leading-tight p-2 w-1/2">
        <div>{question}</div>
      </label>
      <div className="w-1/2  flex flex-row items-center">
        <label className={'flex flex-row items-center ml-2 leading-tight'}>
          <div>{t('Yes')}</div>
          <RadioButton
            className={'ml-2'}
            onClick={() => {
              setValue(true)
            }}
            onChange={() => {}}
            checked={value}
          />
        </label>
        <label className={'flex flex-row items-center ml-2 leading-tight'}>
          <div>{t('No')}</div>
          <RadioButton
            className={'ml-2'}
            onClick={() => {
              setValue(false)
            }}
            onChange={() => {}}
            checked={!value}
          />
        </label>
      </div>
    </div>
  )
}
