import isBoolean from 'lodash-es/isBoolean'
import mapValues from 'lodash-es/mapValues'
import { useMemo } from 'react'
import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom'

import { RoleId, useCurrentUser, useFacilityFeatures } from './api'
import useAuth from './auth'
import CenterSpinner from './components/CenterSpinner'
import { useCurrentFacility } from './hooks'
import useActiveRole from './state/role'

interface RouteConfigEntry {
  isAllowed: boolean
  isInMenu: boolean
}

const DEFAULT_ROUTE_CONFIG_ENTRY = { isAllowed: false, isInMenu: true }

function useCurrentRoleRouteConfig(): { isLoading: boolean; routeConfig: { [key: string]: RouteConfigEntry } } {
  const [roleQuery] = useActiveRole()
  const facilityQuery = useCurrentFacility()
  const facilityFeatures = useFacilityFeatures(facilityQuery.facilityId).data
  const { isLoggedIn } = useAuth()

  return useMemo(() => {
    const hasOrganizations = !!roleQuery.data?.organizations?.length
    const isSignedUpForManufacturerProfile = roleQuery.data?.signUpSourceId === 'MANUFACTURER_SURVEY'
    const isSignedUpForHandlerProfile = roleQuery.data?.signUpSourceId === 'HANDLER_SURVEY'
    const isSignedUpForRecyclerProfile = roleQuery.data?.signUpSourceId === 'RECYCLER_SURVEY'

    const inputRouteConfig = {
      [RoleId.Admin]: {
        '/admin/users': true,
        '/admin/organisations': true,
        '/admin/brands': true,
        '/admin/manufacturers': true,
        '/admin/handlers': true,
        '/admin/recyclers': true,
        '/admin/inventory': true,
        '/admin/shipments': true,
        '/facilities': { isAllowed: true, isInMenu: false },
        '/admin/purchase-orders': true,
        '/admin/production': true,
        '/admin/transaction-overview': true,
      },
      [RoleId.InventoryManager]: {
        '/profile': true,
        '/inventory': true,
        '/handlers': facilityQuery.isRecycler || facilityQuery.isFactory,
        '/suppliers': facilityQuery.isRecycler || facilityQuery.isHandler,
        '/suppliers-overview': facilityQuery.isRecycler || facilityQuery.isHandler,
        '/purchase-orders': facilityQuery.isFactory || (facilityQuery.isHandler && facilityFeatures?.poCreation),
        '/transaction-overview': facilityQuery.isHandler,
        '/shipments': true,
        '/recyclers': facilityQuery.isFactory || facilityQuery.isHandler,
        '/production': facilityQuery.isRecycler,
        '/statistics': true,
      },
      [RoleId.OrganizationManager]: {
        '/users': hasOrganizations,
        '/manufacturer-profile': isSignedUpForManufacturerProfile && !hasOrganizations,
        '/handler-profile': isSignedUpForHandlerProfile && !hasOrganizations,
        '/recycler-profile': isSignedUpForRecyclerProfile && !hasOrganizations,
        '/organization':
          (!isSignedUpForManufacturerProfile && !isSignedUpForHandlerProfile && !isSignedUpForRecyclerProfile) ||
          hasOrganizations,
        '/inventory/facility/:id': !isSignedUpForHandlerProfile && hasOrganizations,
        '/recyclers': isSignedUpForHandlerProfile,
        '/facilities': hasOrganizations,
        '/shipments': !isSignedUpForHandlerProfile && hasOrganizations,
        '/statistics': true,
      },
      [RoleId.BrandManager]: {
        '/brand/users': true,
        '/brand/inventory': true,
        '/manufacturers': true,
        '/recyclers': true,
        '/handlers': true,
        '/statistics': true,
      },
      [RoleId.ProjectManager]: {
        '/recyclers': true,
        '/handlers': true,
        '/manufacturers': true,
        '/project/users': true,
        '/statistics': !!roleQuery.data?.projects?.[0]?.reportLinks?.length,
      },
      [RoleId.Unknown]: {
        '/login': !isLoggedIn,
        '/sign-up/:signUpCode?': !isLoggedIn,
        '/sign-up/manufacturer-profile/:signUpCode': !isLoggedIn,
        '/sign-up/handler-profile/:signUpCode': !isLoggedIn,
        '/sign-up/recycler-profile/:signUpCode': !isLoggedIn,
      },
    }

    const roleId = roleQuery.data?.roleId ?? RoleId.Unknown
    const routeConfig =
      roleQuery.isLoading || facilityQuery.isLoading
        ? {}
        : mapValues(inputRouteConfig[roleId], (route) => {
            const normalizedConfig = isBoolean(route) ? { isAllowed: route } : route
            return Object.assign({}, DEFAULT_ROUTE_CONFIG_ENTRY, normalizedConfig)
          })
    return { isLoading: roleQuery.isLoading, routeConfig }
  }, [
    roleQuery.isLoading,
    facilityQuery.isLoading,
    roleQuery.data,
    facilityQuery.isFactory,
    facilityQuery.isHandler,
    facilityQuery.isRecycler,
    facilityFeatures,
    isLoggedIn,
  ])
}

export default function useAllowedRoutes(): { isLoading: boolean; allowedRoutes: string[] } {
  const { isLoading, routeConfig } = useCurrentRoleRouteConfig()
  const allowedRoutes = Object.entries(routeConfig)
    .filter(([, config]) => config.isAllowed)
    .map(([route]) => route)
  return { isLoading, allowedRoutes }
}

export function useMenuRoutes(): { isLoading: boolean; menuRoutes: string[] } {
  const { isLoading, routeConfig } = useCurrentRoleRouteConfig()
  const menuRoutes = Object.entries(routeConfig)
    .filter(([, config]) => config.isAllowed && config.isInMenu)
    .map(([route]) => route)
  return { isLoading, menuRoutes }
}

export const RoleSpecificRoutes = () => {
  const location = useLocation()
  const { allowedRoutes, isLoading: isRouteLoading } = useAllowedRoutes()
  const { isLoading: isAuthLoading } = useAuth()
  // Hiding the route while loading to prevent fetching data for an unauthorised view based on the current user's role.
  // Also waiting for authentication to complete, as allowed routes are set for guests until the user's role is determined.
  if (isAuthLoading || isRouteLoading) {
    return <CenterSpinner />
  }

  const hasAccess = allowedRoutes.some((route) => {
    // First, check for an exact match
    const isExactMatch = matchPath({ path: route, end: true }, location.pathname)
    if (isExactMatch) {
      return true
    }
    // If no exact match, check for subpath match e.g. shipments/incoming
    return matchPath({ path: `${route}/*`, end: false }, location.pathname)
  })
  return hasAccess ? <Outlet /> : <Navigate to={allowedRoutes[0]} replace />
}

export const PrivateRoutes = () => {
  const { data, isLoading } = useCurrentUser()
  if (isLoading) {
    return <CenterSpinner />
  }
  // If user is logged in, render the the child routes using <Outlet /> for the current route.
  // Otherwise, redirect to the home page.
  return !!data?.user ? <Outlet /> : <Navigate to="/" />
}
