import { isNil, kebabCase, mapKeys, mapValues, omitBy } from 'lodash'
import isArray from 'lodash/isArray'
import { useCallback } from 'react'
import { QueryFunction, QueryFunctionContext, UseQueryOptions, useQuery } from 'react-query'

import { getAuthHeaders } from 'src/auth'
import { GlobalMessageService } from 'src/state/globalMessage/utils'

type QueryParams = { [key: string]: string | number | boolean | (string | number | boolean)[] }
export type MyQueryKey = [string, QueryParams?, ...any]

const defaultQueryFn = async <TResult>(context: QueryFunctionContext<MyQueryKey>): Promise<TResult> => {
  const [path, queryParams] = context.queryKey
  const response = await defaultFetch(path, queryParams)
  return await parseResponse(response)
}

// getQueryParamsFromObject takes the incoming `item` object and turns it into a query params object
// for use with react-query by making the keys kebab-case and removing keys with empty values (optional)
export function getQueryParamsFromObject(
  item: { [key: string | number]: string[] | string | number | boolean | null | undefined } | undefined | null,
  skipEmpty = true,
) {
  let filteredObject: {
    [key: string | number]: string[] | string | number | boolean
  }
  if (skipEmpty) {
    filteredObject = omitBy(item || {}, isNil) as typeof filteredObject
  } else {
    filteredObject = mapValues(item || {}, (v) => v ?? '')
  }

  return mapKeys(filteredObject, (_, k) => kebabCase(k))
}

export interface AnyObject {
  [key: string]: any
}

export class ClientError extends Error {
  detail: AnyObject | string
  status: number
  data: AnyObject

  constructor(status: number, detail: AnyObject | string, data: AnyObject, ...params: any[]) {
    super(...params)
    this.name = 'ClientError'
    this.status = status
    this.detail = detail
    this.data = data
  }
}

export class UnauthenticatedError extends Error {
  detail: AnyObject | string
  status: number
  data: AnyObject

  constructor(status: number, detail: AnyObject | string, data: AnyObject, ...params: any[]) {
    super(...params)
    this.name = 'UnauthenticatedError'
    this.status = status
    this.detail = detail
    this.data = data
  }
}

export const parseResponse = async (r: Response, type: 'text' | 'blob' | 'json' | 'none' = 'json') => {
  if (!r.ok) {
    const [message, data] = await parseResponseError(r)

    if (r.status === 402) {
      GlobalMessageService.sendMessage(message?.toString())
      return data
    }
    if (r.status === 401) {
      throw new UnauthenticatedError(r.status, message, data)
    }

    throw new ClientError(r.status, message, data)
  }
  if (type !== 'none') {
    return await r[type]()
  }
}

async function parseResponseError(r: Response) {
  let message = await r.text()
  let data = {}
  try {
    const { detail, ...parsedData } = JSON.parse(message) ?? {}
    message = detail ?? message
    data = parsedData
  } catch {}
  return [message, data]
}

export const readDataUri = async (blob: Blob) =>
  new Promise<string>(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(this.result as string)
    }
    reader.onerror = function () {
      reject(this.error)
    }
    reader.readAsDataURL(blob)
  })

export const defaultFetch = async (path: string, queryParams?: QueryParams, headers = {}) => {
  const authHeaders = await getAuthHeaders()
  return await fetch(getUrl(path, queryParams), {
    credentials: 'include',
    mode: 'cors',
    headers: { ...authHeaders, ...(headers || {}), Accept: 'application/json' },
  })
}

export const useFetchUpdate = <TResult, TData extends object | void = void>(method: 'PATCH' | 'POST' | 'PUT') =>
  useCallback(
    async (path: string, payload?: TData, queryParams?: QueryParams): Promise<TResult> => {
      const headers = await getAuthHeaders()
      const r = await fetch(getUrl(path, queryParams), {
        method,
        credentials: 'include',
        mode: 'cors',
        headers: {
          ...headers,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: payload ? JSON.stringify(payload) : undefined,
      })
      return await parseResponse(r)
    },
    [method],
  )

export const useFetchDelete = () =>
  useCallback(async (path: string, queryParams?: QueryParams): Promise<void> => {
    const headers = await getAuthHeaders()
    const r = await fetch(getUrl(path, queryParams), {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers,
    })
    return await parseResponse(r, 'none')
  }, [])

export const queryConfig: UseQueryOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  useErrorBoundary: true,
  staleTime: Infinity,
  retry: (failureCount, error) => {
    if (error instanceof ClientError || error instanceof UnauthenticatedError) {
      return false
    }
    return failureCount < 3
  },
}

export const useTokenQuery = <TResult = unknown, TError = unknown, TData = TResult>(
  key: MyQueryKey,
  queryFn?: QueryFunction<TResult, MyQueryKey>,
  options?: UseQueryOptions<TResult, TError, TData, MyQueryKey>,
) => {
  const actualQueryFn = queryFn ?? options?.queryFn ?? defaultQueryFn
  return useQuery<TResult, TError, TData, MyQueryKey>(key, (context) => actualQueryFn(context), options)
}

export function getUrl(path: string, queryParams?: QueryParams) {
  const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/${path}`)
  if (queryParams) {
    Object.entries(queryParams)
      // if value is a list, repeat the same key with each value for correct query format
      .flatMap(([key, value]) => (isArray(value) ? value.map((v) => [key, v] as const) : [[key, value] as const]))
      .forEach(([key, value]) => url.searchParams.append(key, value?.toString() ?? ''))
  }
  return url.toString()
}
