import classNames from 'classnames'
import { Children, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBinoculars, FaBuilding, FaClipboardList, FaSuitcase, FaUsers } from 'react-icons/fa'
import { IoMdCart } from 'react-icons/io'
import { IconType } from 'react-icons/lib'
import { MdLocalOffer } from 'react-icons/md'
import { Link, matchPath, useLocation } from 'react-router-dom'

import { RoleId } from 'src/api'
import CenterSpinner from 'src/components/CenterSpinner'
import { FacilityIcon, InventoryIcon, ProductionIcon, ShipmentIcon, StatisticsIcon } from 'src/icons'
import { useMenuRoutes } from 'src/route-config'
import useActiveRole from 'src/state/role'

import { LabeledIconButton } from './Buttons'
import LoaderWrapper from './LoaderWrapper'

export default function MainMenu({ className, isMenuOpen, closeMenu }) {
  const [roleQuery] = useActiveRole()
  const { t } = useTranslation()

  return (
    <>
      {isMenuOpen && <div className="fixed inset-0 bg-rr-black bg-opacity-50 md:hidden z-40" onClick={closeMenu} />}
      <nav
        className={classNames(
          'fixed md:static mt-0 bg-teal-500 h-full transition-transform transform md:mt-20 w-40 md:w-20',
          isMenuOpen ? 'translate-x-0' : '-translate-x-full',
          'md:translate-x-0 md:block',
          'z-50',
          className,
        )}
      >
        <LoaderWrapper query={roleQuery}>
          <MenuWrapper>
            <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/admin/users" onClick={closeMenu} />
            <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/users" onClick={closeMenu} />
            <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/brand/users" onClick={closeMenu} />
            <MenuItem label={t('MAIN_MENU`Users')} icon={FaUsers} link="/project/users" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Organisations')}
              icon={FaBuilding}
              link="/admin/organisations"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Profile')} icon={FaClipboardList} link="/profile" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Profile')}
              icon={FaClipboardList}
              link="/handler-profile"
              onClick={closeMenu}
            />
            <MenuItem
              label={t('MAIN_MENU`Profile')}
              icon={FaClipboardList}
              link="/manufacturer-profile"
              onClick={closeMenu}
            />
            <MenuItem
              label={t('MAIN_MENU`Manufacturers')}
              icon={InventoryIcon}
              link="/suppliers-overview"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Inventory')} icon={InventoryIcon} link="/inventory" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Inventory')}
              icon={InventoryIcon}
              link="/inventory/facility/:id"
              onClick={closeMenu}
            />
            <MenuItem
              label={t('MAIN_MENU`Inventory')}
              icon={InventoryIcon}
              link="/admin/inventory"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Facilities')} icon={FacilityIcon} link="/facilities" onClick={closeMenu} />
            <MenuItem
              label={
                roleQuery.data?.roleId === RoleId.BrandManager
                  ? t('MAIN_MENU`My Manufacturers')
                  : t('MAIN_MENU`Manufacturers')
              }
              icon={FacilityIcon}
              link="/manufacturers"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Brands')} icon={MdLocalOffer} link="/admin/brands" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Manufacturers')}
              icon={FacilityIcon}
              link="/admin/manufacturers"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Handlers')} icon={FacilityIcon} link="/admin/handlers" onClick={closeMenu} />
            <MenuItem label={t('MAIN_MENU`Handlers')} icon={FacilityIcon} link="/handlers" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Recyclers')}
              icon={FacilityIcon}
              link="/admin/recyclers"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Organisation')} icon={FaSuitcase} link="/organization" onClick={closeMenu} />
            <MenuItem label={t('MAIN_MENU`Recyclers')} icon={FacilityIcon} link="/recyclers" onClick={closeMenu} />
            <MenuItem label={t('MAIN_MENU`Shipments')} icon={ShipmentIcon} link="/shipments" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Shipments')}
              icon={ShipmentIcon}
              link="/admin/shipments"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Statistics')} icon={StatisticsIcon} link="/statistics" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Purchase orders')}
              icon={IoMdCart}
              link="/admin/purchase-orders"
              onClick={closeMenu}
            />
            <MenuItem
              label={t('MAIN_MENU`Purchase orders')}
              icon={IoMdCart}
              link="/purchase-orders"
              onClick={closeMenu}
            />
            <MenuItem label={t('MAIN_MENU`Production')} icon={ProductionIcon} link="/production" onClick={closeMenu} />
            <MenuItem
              label={t('MAIN_MENU`Production')}
              icon={ProductionIcon}
              link="/admin/production"
              onClick={closeMenu}
            />
            <MenuItem
              label={t('MAIN_MENU`Transaction overview')}
              icon={FaBinoculars}
              link="/admin/transaction-overview"
              onClick={closeMenu}
            />
            <MenuItem
              label={t('MAIN_MENU`Transaction overview')}
              icon={FaBinoculars}
              link="/transaction-overview"
              onClick={closeMenu}
            />
          </MenuWrapper>
        </LoaderWrapper>
      </nav>
    </>
  )
}

const MenuWrapper = ({ children }) => {
  const { menuRoutes, isLoading } = useMenuRoutes()
  return isLoading ? (
    <CenterSpinner />
  ) : (
    <ul className="sticky md:top-10 top-4 mt-10 md:pb-80 h-auto">
      {Children.toArray(children).filter((c) => isValidElement(c) && menuRoutes.includes(c.props.link))}
    </ul>
  )
}

const MenuItem = ({
  icon: Icon,
  link,
  label,
  onClick,
}: {
  icon: IconType
  link: string
  label: string
  onClick?: () => void
}) => {
  const location = useLocation()
  const match = matchPath({ path: link, end: false }, location.pathname)

  return (
    <li>
      <LabeledIconButton
        as={Link}
        variantColor="white"
        to={link}
        icon={() => <Icon className="w-10 h-10" />}
        className={classNames('p-2 flex flex-col items-center justify-center space-y-1.5', {
          'bg-teal-600': match,
        })}
        title={label}
        onClick={onClick}
      >
        <span className="text-base md:text-xs">{label}</span>
      </LabeledIconButton>
    </li>
  )
}
