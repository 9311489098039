import { getIn } from 'formik'
import isString from 'lodash/isString'
import { useTranslation } from 'react-i18next'

import { ErrorResponse } from 'src/api'

export default function ErrorText({
  error,
  name,
  prefix,
  errorValueKey = 'defaultValue',
  className,
}: {
  error: ErrorResponse | null
  name?: string
  prefix?: string
  errorValueKey?: string
  className?: string
}) {
  const errorDetail = (name && getIn(error?.detail, name)) ?? error?.detail ?? error
  const { t } = useTranslation()
  const { t: tx } = useTranslation()
  if (!errorDetail) return null
  return (
    <p className={`text-red-600 ${className}`}>
      {isString(errorDetail)
        ? tx([prefix ?? errorDetail], { [errorValueKey]: tx(errorDetail), ns: 'error' })
        : t('An unknown error has occurred', { ns: 'error' })}
    </p>
  )
}
