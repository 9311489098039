import { Auth } from '@aws-amplify/auth'

import authConfiguration from './amplify-auth-configuration'
import Authentication from './authentication'

export function configureAuth() {
  Auth.configure(authConfiguration)
}

export const AuthProvider = Authentication.Provider

const useAuth = Authentication.useContainer
export default useAuth
export { getAuthHeaders } from './token'
