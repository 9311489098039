import { useCallback } from 'react'
import { UseMutationResult } from 'react-query'

import { DownloadResponse, ErrorResponse, FileAttachment, ShipmentAttachmentDownloadMutationParams } from 'src/api'
import { downloadUri } from 'src/utils'

export function useDownloadFile(downloadQuery: UseMutationResult<DownloadResponse, ErrorResponse, number>) {
  const mutateAsync = downloadQuery.mutateAsync
  return useCallback(
    async (attachment: FileAttachment) => {
      const { url } = await mutateAsync(attachment.id)
      downloadUri(url, attachment.name, true)
    },
    [mutateAsync],
  )
}

export function useDownloadShipmentFile(
  downloadQuery: UseMutationResult<DownloadResponse, ErrorResponse, ShipmentAttachmentDownloadMutationParams>,
) {
  const mutateAsync = downloadQuery.mutateAsync
  return useCallback(
    async (shipmentId: number, kind: 'sender' | 'receiver', attachment: FileAttachment) => {
      const { url } = await mutateAsync({ shipmentId, kind, attachmentId: attachment.id })
      downloadUri(url, attachment.name, true)
    },
    [mutateAsync],
  )
}
