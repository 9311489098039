import classNames from 'classnames'
import { IconType } from 'react-icons'

import { ComponentWithAs } from 'src/components/type-utils'

export type ButtonProps = {
  icon?: IconType
  variantColor?: 'teal' | 'gray' | 'lightGray' | 'red' | 'white' | 'darkYellow' | 'darkGray' | 'blue'
  size?: 'xs' | 'sm' | 'base' | 'lg'
}

export const BlankButton: ComponentWithAs<ButtonProps> = ({
  className,
  children = undefined,
  disabled = false,
  variantColor = 'teal',
  icon: Icon = undefined,
  as: Element = 'button',
  size = 'sm',
  ...props
}) => (
  <Element
    className={classNames(
      'focus:outline-none focus:shadow-outline',
      !disabled && {
        'text-teal-500 hover:text-teal-600 active:text-teal-700': variantColor === 'teal',
        'text-slate-700 hover:text-slate-800 active:text-black': variantColor === 'darkGray',
        'text-slate-500 hover:text-slate-600 active:text-slate-700': variantColor === 'gray',
        'text-slate-300 hover:text-slate-400 active:text-slate-500': variantColor === 'lightGray',
        'text-white hover:text-slate-50 active:text-slate-100': variantColor === 'white',
        'text-red-500 hover:text-red-600 active:text-red-700': variantColor === 'red',
        'text-yellow-600 hover:text-yellow-700 active:text-yellow-800': variantColor === 'darkYellow',
        'text-sky-600 hover:text-sky-700 active:text-sky-800': variantColor === 'blue',
      },
      {
        'pointer-events-none text-slate-400 border-slate-300': disabled,
        'inline-flex items-center': !!Icon,
        'text-xs': size === 'xs',
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
      },
      className,
    )}
    type={Element === 'button' ? 'button' : undefined}
    disabled={disabled}
    {...props}
  >
    <>{Icon && <Icon className="w-4 h-4 mr-1 flex-shrink-0" />}</>
    {children ?? null}
  </Element>
)

export const Button: ComponentWithAs<ButtonProps> = ({
  className,
  children,
  variantColor = 'teal',
  disabled = false,
  icon: Icon,
  ...props
}) => (
  <BlankButton
    className={classNames(
      'whitespace-nowrap inline-block py-2 min-w-120px uppercase font-semibold rounded tracking-wide print:hidden',
      {
        'bg-teal-500 hover:bg-teal-600 active:bg-teal-700': variantColor === 'teal' && !disabled,
        'bg-slate-500 hover:bg-slate-600 active:bg-slate-700': variantColor === 'gray' && !disabled,
        'bg-slate-300 hover:bg-slate-400 active:bg-slate-500': variantColor === 'lightGray' && !disabled,
        'bg-white': variantColor === 'white' && !disabled,
        'bg-red-500 hover:bg-red-600 active:bg-red-700': variantColor === 'red' && !disabled,
        'bg-yellow-700 hover:bg-yellow-800 active:bg-yellow-900': variantColor === 'darkYellow' && !disabled,
        'bg-slate-300 ': disabled,
        'px-3': !Icon,
        'inline-flex pl-2 pr-3': !!Icon,
      },
      className,
    )}
    icon={Icon}
    variantColor={variantColor === 'white' ? 'teal' : variantColor === 'lightGray' ? 'darkGray' : 'white'}
    disabled={disabled}
    {...props}
  >
    {children && <div className="flex-grow h-full flex justify-center items-center">{children}</div>}
  </BlankButton>
)

export const CtaButton: ComponentWithAs<ButtonProps> = ({
  className,
  children,
  variantColor = 'teal',
  disabled = false,
  icon: Icon,
  ...props
}) => (
  <BlankButton
    className={classNames(
      'whitespace-nowrap inline-block py-10 px-20 min-w-120px font-semibold rounded-xl tracking-wide print:hidden text-lg',
      {
        'bg-teal-500 hover:bg-teal-600 active:bg-teal-700': variantColor === 'teal' && !disabled,
        'bg-slate-500 hover:bg-slate-600 active:bg-slate-700': variantColor === 'gray' && !disabled,
        'bg-slate-300 hover:bg-slate-400 active:bg-slate-500': variantColor === 'lightGray' && !disabled,
        'bg-white': variantColor === 'white' && !disabled,
        'bg-red-500 hover:bg-red-600 active:bg-red-700': variantColor === 'red' && !disabled,
        'bg-yellow-700 hover:bg-yellow-800 active:bg-yellow-900': variantColor === 'darkYellow' && !disabled,
        'bg-slate-300 ': disabled,
        'inline-flex': !!Icon,
      },
      className,
    )}
    icon={Icon}
    variantColor={variantColor === 'white' ? 'teal' : 'white'}
    disabled={disabled}
    {...props}
  >
    {children && <div className="text-center flex-grow">{children}</div>}
  </BlankButton>
)

export const OutlineButton: ComponentWithAs<ButtonProps> = ({
  className,
  children,
  variantColor = 'teal',
  wrapLabel = false,
  icon: Icon,
  disabled,
  ...props
}) => (
  <BlankButton
    variantColor={variantColor}
    className={classNames(
      'px-2 py-2 font-semibold uppercase inline-block text-left border rounded',
      !disabled && {
        'border-teal-500 hover:border-teal-600 active:border-teal-700': variantColor === 'teal',
        'border-slate-300 hover:border-slate-400 active:border-slate-500': variantColor === 'lightGray',
        'border-slate-500 hover:border-slate-600 active:border-slate-700': variantColor === 'gray',
        'border-red-500 hover:border-red-600 active:border-red-700': variantColor === 'red',
        'border-yellow-600 hover:border-yellow-700 active:border-yellow-800': variantColor === 'darkYellow',
      },
      {
        'flex items-center': !!Icon,
        'whitespace-nowrap ': !wrapLabel,
      },
      className,
    )}
    disabled={disabled}
    icon={Icon}
    {...props}
  >
    {children}
  </BlankButton>
)

export const TextButton: ComponentWithAs<ButtonProps> = ({
  icon: Icon = undefined,
  variantColor = 'gray',
  className = undefined,
  children,
  ...props
}) => (
  <BlankButton
    {...props}
    variantColor={variantColor}
    className={classNames(
      className,
      'py-2 px-2 font-semibold uppercase items-center leading-5 self-center print:hidden',
      { 'text-center': !Icon, 'inline-flex items-center': !!Icon },
    )}
    icon={Icon}
  >
    {children}
  </BlankButton>
)

export const IconButton: ComponentWithAs<Omit<ButtonProps & { icon: IconType }, 'children'>> = ({
  icon: Icon,
  className = undefined,
  ...props
}) => (
  <BlankButton {...props} className={classNames(className, 'print:hidden')}>
    <div className="scale-svg">
      <Icon className="w-full h-full" />
    </div>
  </BlankButton>
)

export const BoxedIconButton: ComponentWithAs<Omit<ButtonProps & { icon: IconType }, 'children'>> = ({
  icon: Icon,
  className = undefined,
  disabled,
  variantColor = 'teal',
  ...props
}) => (
  <BlankButton
    {...props}
    variantColor={variantColor === 'white' ? 'teal' : 'white'}
    disabled={disabled}
    className={classNames(className, 'border rounded p-1 print:hidden', {
      'bg-teal-500 hover:bg-teal-600 active:bg-teal-700': variantColor === 'teal' && !disabled,
      'bg-slate-500 hover:bg-slate-600 active:bg-slate-700': variantColor === 'gray' && !disabled,
      'bg-slate-300 hover:bg-slate-400 active:bg-slate-500': variantColor === 'lightGray' && !disabled,
      'bg-white': variantColor === 'white' && !disabled,
      'bg-red-500 hover:bg-red-600 active:bg-red-700': variantColor === 'red' && !disabled,
      'bg-yellow-700 hover:bg-yellow-800 active:bg-yellow-900': variantColor === 'darkYellow' && !disabled,
      'bg-slate-300 ': disabled,
    })}
  >
    <div className="scale-svg">
      <Icon className="w-full h-full" />
    </div>
  </BlankButton>
)

export const BoxedOutlineIconButton: ComponentWithAs<Omit<ButtonProps & { icon: IconType }, 'children'>> = ({
  icon: Icon,
  className = undefined,
  disabled,
  variantColor = 'teal',
  ...props
}) => (
  <BlankButton
    {...props}
    variantColor={variantColor}
    disabled={disabled}
    className={classNames(className, 'border rounded p-1 print:hidden', {
      'border-teal-500 hover:border-teal-600 active:border-teal-700': variantColor === 'teal',
      'border-slate-300 hover:border-slate-400 active:border-slate-500': variantColor === 'lightGray',
      'border-slate-500 hover:border-slate-600 active:border-slate-700': variantColor === 'gray',
      'border-red-500 hover:border-red-600 active:border-red-700': variantColor === 'red',
      'border-yellow-600 hover:border-yellow-700 active:border-yellow-800': variantColor === 'darkYellow',
    })}
  >
    <div className="scale-svg">
      <Icon className="w-full h-full" />
    </div>
  </BlankButton>
)

export const LabeledIconButton: ComponentWithAs<ButtonProps & { icon: IconType }> = ({
  icon: Icon,
  className = undefined,
  children,
  ...props
}) => (
  <BlankButton {...props} className={classNames(className, 'print:hidden')}>
    <Icon className="w-full h-full px-3 py-1" preserveAspectRatio="xMidYMid meet" />
    <div className="text-xs w-full leading-tight text-center">{children}</div>
  </BlankButton>
)

export const TextAnchorButton: ComponentWithAs<ButtonProps> = ({
  icon: Icon,
  children,
  className,
  size = 'base',
  ...props
}) => (
  <BlankButton className={classNames(className, 'cursor-pointer')} size={size} {...props}>
    <span className="underline">{children}</span>
    {Icon && (
      <>
        {' '}
        <span className="w-4 inline-block align-text-top">
          <span className="block scale-svg">
            <Icon />
          </span>
        </span>
      </>
    )}
  </BlankButton>
)

export const TextAnchor = ({
  href,
  children,
  className,
  size = 'base',
  ...props
}: JSX.IntrinsicElements['a'] & ButtonProps) => (
  <BlankButton
    as="a"
    href={href}
    size={size}
    className={classNames(className, 'cursor-pointer underline')}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
  >
    {children}
  </BlankButton>
)
