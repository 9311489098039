import React, { Component, ComponentType, ErrorInfo, ReactNode } from 'react'

export interface ErrorBoundaryProps {
  children: ReactNode
  FallbackComponent: ComponentType<{
    error: Error
    info: React.ErrorInfo
    clearError: () => void
  }>
}
type ErrorBoundaryState = { hasError: boolean; error: Error; info: ErrorInfo }

/*
  Error boundary with fallback component
*/
export default class BaseErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error, info: ErrorInfo) {
    return { error, info, hasError: true }
  }

  clearError = () => {
    this.setState({})
  }

  render() {
    const { hasError, error, info } = this.state || {}
    const { children, FallbackComponent } = this.props
    return hasError ? <FallbackComponent error={error} info={info} clearError={this.clearError} /> : children
  }
}
