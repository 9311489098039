import { useCallback, useEffect, useState } from 'react'

export default function useIntroPageCookie() {
  const [cookie, setCookie] = useCookie('HAS_SEEN_INTRO_PAGE', '')
  return [cookie, useCallback(() => setCookie('1', Infinity), [setCookie])] as const
}

export function useTrackingConsentPopupCookie() {
  const [cookie, setCookie] = useCookie('HAS_CLOSED_TRACKING_CONSENT_POPUP', '')
  return [
    cookie === '1' ? true : cookie === '0' ? false : null,
    useCallback((expanded) => setCookie(expanded ? '1' : '0', Infinity), [setCookie]),
  ] as const
}

function useRemoveLegacyPopupCookie() {
  const [legacyCookie, setLegacyCookie] = useCookie('HAS_CLOSED_TRACKING_CONSENT_POPUP', '')

  useEffect(() => {
    if (legacyCookie !== '') {
      setLegacyCookie('', 0)
    }
  }, [legacyCookie, setLegacyCookie])
}

export function useTrackingConsentCookie() {
  useRemoveLegacyPopupCookie()

  const [cookie, setCookie] = useCookie('TRACKING_CONSENT', '')
  return [
    cookie === '1' ? true : cookie === '0' ? false : null,
    useCallback((givesConsent) => setCookie(givesConsent ? '1' : '0', Infinity), [setCookie]),
  ] as const
}

export function useCookie(key: string, defaultValue: string) {
  const [stateCookie, setStateCookie] = useState(() => getBrowserCookie(key, defaultValue))
  return [
    stateCookie,
    useCallback(
      (value: string, numberOfDays: number) => {
        setStateCookie(value)
        setBrowserCookie(key, value, numberOfDays)
      },
      [key],
    ),
  ] as const
}

function getBrowserCookie(key: string, defaultValue: string) {
  return document.cookie.split('; ').reduce((defaultValue, currentCookie) => {
    const [storedKey, storedValue] = currentCookie.split('=')
    return key === storedKey ? decodeURIComponent(storedValue) : defaultValue
  }, defaultValue)
}

function setBrowserCookie(key: string, value: string, numberOfDays = 0) {
  let expires: Date | null = null
  if (!isFinite(numberOfDays)) {
    expires = new Date(2038, 1, 0)
  } else if (numberOfDays > 0) {
    expires = new Date(Date.now() + numberOfDays)
  }

  const cookieString = `${key}=${encodeURIComponent(value)};${
    expires ? ` expires=${expires.toUTCString()}` : ''
  }; path=/; samesite=Strict`
  document.cookie = cookieString
}
