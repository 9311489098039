import { Formik, FormikConfig, FormikHelpers, FormikValues, useFormikContext } from 'formik'
import cloneDeepWith from 'lodash/cloneDeepWith'
import { memo, useCallback, useMemo } from 'react'

/*
  Wrapper to formik what converts null -> '' for input and '' -> null for output.
  React does not support null values for controlled fields, but we want to send nulls instead of empty strings to server
  partially because '' does not validate as int | None. This can be removed once Formik supports transforming input / output values
  Also handles lack of onSubmit to fall back to a read-only form
*/

const Normik = <Values extends FormikValues = FormikValues, ExtraProps = {}>({
  initialValues,
  onSubmit,
  ...props
}: Omit<FormikConfig<Values>, 'onSubmit'> &
  ExtraProps & {
    onSubmit?: FormikConfig<Values>['onSubmit']
  }) => {
  const convertedInitialValues = useMemo(() => convertNullsToEmptyStrings(initialValues), [initialValues])
  const convertedOnSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) =>
      onSubmit?.(convertEmptyStringsToNulls(values), formikHelpers),
    [onSubmit],
  )
  return (
    <Formik
      initialValues={convertedInitialValues}
      onSubmit={convertedOnSubmit}
      initialStatus={onSubmit ? undefined : 'READ_ONLY'}
      {...props}
    />
  )
}

export const convertEmptyStringsToNulls = <Values extends FormikValues>(values: Values): Values =>
  cloneDeepWith(values, (v) => (v === '' ? null : undefined))
export const convertNullsToEmptyStrings = <Values extends FormikValues>(values: Values): Values =>
  cloneDeepWith(values, (v) => (v === null ? '' : undefined))

export default memo(Normik) as typeof Normik // workaround for memo losing generic props types

export function useIsFormEditable() {
  const context = useFormikContext()
  return context.status !== 'READ_ONLY'
}
