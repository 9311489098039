import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { ComponentType, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'src/components/Buttons'

import BaseErrorBoundary from './BaseErrorBoundary'

const apiToken = process.env.REACT_APP_BUGSNAG_API_TOKEN

if (apiToken) {
  Bugsnag.start({
    apiKey: apiToken,
    plugins: [new BugsnagPluginReact()],
  })
}

const ReportingErrorBoundary = ({ children }) => {
  const ResolvedErrorBoundary =
    (apiToken && Bugsnag.getPlugin('react')?.createErrorBoundary(React)) || BaseErrorBoundary
  return <ResolvedErrorBoundary FallbackComponent={ErrorView}>{children}</ResolvedErrorBoundary>
}

export default ReportingErrorBoundary

export function withReportingErrorBoundary<T extends { children?: ReactNode }>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  const WrappingComponent = (props: T) => (
    <ReportingErrorBoundary>
      <WrappedComponent {...props}>{props.children}</WrappedComponent>
    </ReportingErrorBoundary>
  )

  WrappingComponent.displayName = `withReportingErrorBoundary(${displayName})`

  return WrappingComponent
}

function ErrorView({ error, info }) {
  const { t } = useTranslation()
  const { t: tx } = useTranslation()
  return (
    <div className="flex p-5 mt-20 flex-col shadow border flex-grow items-center">
      <p>
        {t('Error: {{value}}', {
          value: tx(typeof error?.detail === 'string' ? error.detail : error?.toString()),
          ns: 'error',
        })}
      </p>
      <div className="mt-5 space-x-2">
        <Button as="a" href="/">
          {t('Refresh')}
        </Button>
      </div>
    </div>
  )
}
