import { Facility, InventoryModification } from '.'
import isString from 'lodash/isString'
import mapValues from 'lodash/mapValues'
import { useMutation, useQueryClient } from 'react-query'

import { Pagination } from './admin'
import { ClientError, useFetchDelete, useFetchUpdate, useTokenQuery } from './query'
import { ErrorResponse } from './request'

export enum RecycledProductStatusId {
  DRAFT = 'DRAFT',
  CONFIRMED = 'CONFIRMED',
  VERIFICATION_REQUESTED = 'VERIFICATION_REQUESTED',
}

export interface NewRecycledProductInputCategory {
  categoryId: number
}

export interface NewRecycledProduct {
  inputCategories: NewRecycledProductInputCategory[]
  brandId?: number
}

export interface RecycledProductInputCategory extends NewRecycledProductInputCategory {
  modifications: InventoryModification[]
}

export interface RecycledProduct {
  id: number
  inputCategories: RecycledProductInputCategory[]
  statusId: RecycledProductStatusId
  facility: Facility
  externalPartnerId: number
  internalPartnerId: number
}

export interface RecycledProductResponse {
  recycledProduct: RecycledProduct
}

export interface RecycledProductListResponse {
  recycledProducts: RecycledProduct[]
  pagination: Pagination
}

export interface RecycledProductStatusUpdate {
  statusId: RecycledProductStatusId
}

export const useRecycledProduct = (facilityId: number | undefined, productId: number | undefined) =>
  useTokenQuery<RecycledProductResponse, ErrorResponse, RecycledProduct>(
    [`v1/recyclers/${facilityId}/recycled-products/${productId}/`],
    undefined,
    {
      select: (data) => data.recycledProduct,
      enabled: !!facilityId && !!productId,
    },
  )

export const useCreateRecycledProduct = (facilityId: number | undefined) => {
  const post = useFetchUpdate<RecycledProductResponse, NewRecycledProduct>('POST') // TODO: types
  const queryClient = useQueryClient()

  return useMutation<RecycledProduct, ErrorResponse, { categoryIds: number[] }>(
    async (data) =>
      (await post(`v1/recyclers/${facilityId}/recycled-products/`, mapToCreateProduct(data))).recycledProduct,
    {
      onSuccess: async () => queryClient.invalidateQueries([`v1/recyclers/${facilityId}/recycled-products/`]),
      onError: (error) => {
        if (error instanceof ClientError && !isString(error.detail)) {
          throw new ClientError(error.status, mapFromCreateProduct(error.detail) ?? error.detail, error.data)
        } else {
          throw error
        }
      },
    },
  )
}

export const useRecycledProducts = (facilityId: number | undefined, limit: number, skip: number) =>
  useTokenQuery<RecycledProductListResponse, ErrorResponse>(
    [`v1/recyclers/${facilityId}/recycled-products/`, { limit, skip }],
    undefined,
    {
      enabled: !!facilityId,
    },
  )

export const useRecycledProductUpdate = (facilityId: number | undefined, productId: number | undefined) => {
  const patch = useFetchUpdate<RecycledProductResponse, RecycledProduct>('PATCH')
  const queryClient = useQueryClient()

  return useMutation<RecycledProduct, ErrorResponse, RecycledProduct>(
    async (data) => (await patch(`v1/recyclers/${facilityId}/recycled-products/${productId}/`, data)).recycledProduct,
    {
      onSuccess: async (recycledProduct) => {
        await Promise.all([
          ...recycledProduct.inputCategories.map((inputCategory) =>
            queryClient.invalidateQueries([`v1/categories/${inputCategory.categoryId}/inventories/`]),
          ),
          ...recycledProduct.inputCategories.map((inputCategory) =>
            queryClient.invalidateQueries([`v1/categories/${inputCategory.categoryId}/stock/`]),
          ),
          queryClient.invalidateQueries([`v1/recyclers/${facilityId}/recycled-products/`]),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
        ])
        queryClient.setQueryData([`v1/recyclers/${facilityId}/recycled-products/${productId}/`], { recycledProduct })
      },
    },
  )
}

export const useRecycledProductStatusUpdate = (productId: number, facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<RecycledProductResponse, RecycledProductStatusUpdate>('POST')
  return useMutation<RecycledProduct, ErrorResponse, RecycledProductStatusUpdate>(
    async ({ statusId }) =>
      (await patch(`v1/recyclers/${facilityId}/recycled-products/${productId}/status/`, { statusId })).recycledProduct,
    {
      onSuccess: (recycledProduct) =>
        Promise.all([
          queryClient.setQueryData([`v1/recyclers/${facilityId}/recycled-products/${productId}/`], { recycledProduct }),
          queryClient.invalidateQueries([`v1/recyclers/${facilityId}/recycled-products/`]),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
          ...recycledProduct.inputCategories.map((inputCategory) =>
            queryClient.invalidateQueries([`v1/categories/${inputCategory.categoryId}/stock/`]),
          ),
        ]),
    },
  )
}

export const useRecycledProductDelete = (productId: number, facilityId: number) => {
  const queryClient = useQueryClient()
  const doDelete = useFetchDelete()
  return useMutation<void, ErrorResponse>(
    async () => doDelete(`v1/recyclers/${facilityId}/recycled-products/${productId}/`),
    {
      onSuccess: () =>
        Promise.all([
          queryClient.removeQueries([`v1/recyclers/${facilityId}/recycled-products/${productId}/`]),
          queryClient.invalidateQueries([`v1/recyclers/${facilityId}/recycled-products/`]),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
        ]),
    },
  )
}

export const useRecycledProductsForCategory = (facilityId: number, categoryId: number) =>
  useTokenQuery<{ recycledProducts: RecycledProduct[] }, ErrorResponse, RecycledProduct[]>(
    [`v1/recyclers/${facilityId}/recycled-products/`, { 'category-id': categoryId, 'only-drafts': true }],
    undefined,
    { select: (data) => data.recycledProducts, enabled: !!facilityId },
  )

function mapToCreateProduct({
  categoryIds,
  brandPartitions,
}: {
  categoryIds: number[]
  brandPartitions?: number
}): NewRecycledProduct {
  return { inputCategories: categoryIds.map((categoryId) => ({ categoryId })), brandId: brandPartitions }
}

function mapFromCreateProduct(object: Object) {
  if (object?.['inputCategories']) {
    return { categoryIds: mapValues((object as NewRecycledProduct).inputCategories, 'categoryId') }
  }
}

export function useAdminRecycledProducts(limit: number, skip: number) {
  return useTokenQuery<RecycledProductListResponse, ErrorResponse>(
    ['v1/admin/recycled-products/', { limit, skip }],
    undefined,
  )
}

export function useAdminRecycledProduct(productId?: number) {
  return useTokenQuery<{ recycledProduct: RecycledProduct }, ErrorResponse, RecycledProduct>(
    [`v1/admin/recycled-products/${productId}/`],
    undefined,
    { select: (r) => r.recycledProduct },
  )
}
