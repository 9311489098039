import { HubCallback } from '@aws-amplify/core/lib/Hub'
import { Auth, Hub } from 'aws-amplify'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createContainer } from 'unstated-next'

import { getToken } from './token'

function useAuthentication() {
  const [isLoggedIn, setIsLoggedIn] = useState(null as any)
  const isMounted = useIsMounted()

  useEffect(() => {
    const checkSession = async () => {
      const token = await getToken()
      if (isMounted.current) {
        setIsLoggedIn(!!token)
      }
    }

    const handleAuthEvent: HubCallback = ({ payload: { event } }) => {
      if (['tokenRefresh', 'signIn'].includes(event)) {
        checkSession()
      }
    }
    checkSession()
    Hub.listen('auth', handleAuthEvent)
    return () => {
      Hub.remove('auth', handleAuthEvent)
    }
  })

  const login = useCallback(async () => {
    await Auth.federatedSignIn()
  }, [])

  const logout = useCallback(async () => {
    await Auth.signOut()
  }, [])

  return {
    login,
    logout,
    isLoggedIn: isLoggedIn === true,
    isLoggedOut: isLoggedIn === false,
    isLoading: isLoggedIn === null,
  }
}

function useIsMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

const Authentication = createContainer(useAuthentication)

export default Authentication
