import { useTranslation } from 'react-i18next'

import { Modal, ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/components/Modal'
import useGlobalMessage from 'src/state/globalMessage'

import { Button } from './Buttons'

export default function GlobalMessageModal() {
  const { message, hasGlobalMessage, resetGlobalMessage } = useGlobalMessage()
  const { t } = useTranslation()

  const onClose = () => {
    resetGlobalMessage()
  }

  return (
    <Modal isOpen={hasGlobalMessage} onClose={onClose}>
      <ModalClose onClose={onClose} />
      <ModalHeader>{t('Attention!')}</ModalHeader>
      <ModalBody className="space-y-2">
        <div className="flex space-x-2 items-center">{message && <p>{message}</p>}</div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>{t('Ok')}</Button>
      </ModalFooter>
    </Modal>
  )
}
