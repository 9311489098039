import { useTranslation } from 'react-i18next'

import { UnauthenticatedError } from 'src/api/query'
import useAuth from 'src/auth'
import { Button } from 'src/components/Buttons'

import BaseErrorBoundary from './BaseErrorBoundary'

function LoginButton() {
  const { t } = useTranslation()
  const { login } = useAuth()
  return <Button onClick={login}>{t('Login')}</Button>
}

function ErrorView({ error, info }) {
  const { t } = useTranslation()
  if (error && !(error instanceof UnauthenticatedError)) {
    throw error // pass to upper level
  }
  return (
    <div className="flex p-5 mt-20 flex-col shadow border flex-grow items-center">
      <p>{t('Your session expired', { ns: 'error' })}</p>
      <div className="mt-5 space-x-2">
        <LoginButton />
      </div>
    </div>
  )
}

export default function AuthenticationErrorBoundary({ children }) {
  return <BaseErrorBoundary FallbackComponent={ErrorView}>{children}</BaseErrorBoundary>
}
