import Bugsnag from '@bugsnag/js'
import { Auth } from 'aws-amplify'

export async function getAuthHeaders() {
  const token = await getToken()
  return { Authorization: token ? `Bearer ${token}` : '' }
}

export async function getToken() {
  try {
    const session = await Auth.currentSession()
    if (session.isValid()) {
      return session.getAccessToken().getJwtToken()
    }
  } catch (error: any) {
    if (process.env.REACT_APP_BUGSNAG_API_TOKEN) {
      Bugsnag.notify(error, (event) => {
        event.context = 'Getting user token'
      })
    }
  }
}
