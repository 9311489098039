import { useState } from 'react'

import { GlobalMessageProvider } from 'src/state/globalMessage'

import GlobalMessageModal from './GlobalMessageModal'
import Header from './Header'
import MainMenu from './MainMenu'
import TrackingConsent from './TrackingConsent'

export default function TopFrame({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <section role="main" className="print:block grid grid-main h-full w-full">
      <GlobalMessageProvider>
        <Header toggleMenu={toggleMenu} className="grid-main-header print:hidden min-w-[600px]" />
        <MainMenu isMenuOpen={isMenuOpen} closeMenu={closeMenu} className="grid-main-sidebar print:hidden" />
        <div className="grid-main-body pb-20">{children}</div>
        <TrackingConsent />
        <GlobalMessageModal />
      </GlobalMessageProvider>
    </section>
  )
}
