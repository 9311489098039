import { useCallback } from 'react'
import { UseQueryResult } from 'react-query'

import { CurrentUser, ErrorResponse, RoleId, useCurrentUser, useCurrentUserActiveRoleUpdate } from 'src/api'

export default function useActiveRole() {
  const userQuery = useCurrentUser()
  const roleUpdateQuery = useCurrentUserActiveRoleUpdate()

  const updateRole = roleUpdateQuery.mutateAsync
  const setRoleId = useCallback((id) => updateRole({ activeRoleId: parseInt(id) }), [updateRole])

  return [
    {
      ...userQuery,
      data: userQuery.data?.activeRole,
      hasAdminRole: userQuery.data?.user?.roles?.some((r) => r.roleId === RoleId.Admin),
      hasDemoRole: userQuery.data?.user?.roles?.some((r) => r.roleId === RoleId.Demo),
      isLoading: userQuery.isLoading || roleUpdateQuery.isLoading,
    } as UseQueryResult<CurrentUser['activeRole'], ErrorResponse> & { hasAdminRole: boolean; hasDemoRole: boolean },
    setRoleId,
  ] as const
}
