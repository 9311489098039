import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

import { GlobalMessageService } from './utils'

function useGlobalMessage() {
  const [message, setMessage] = useState<string | undefined>(undefined)
  GlobalMessageService.onMessage(setMessage, message)

  const setGlobalMessage = (message: string | undefined) => setMessage(message)
  const resetGlobalMessage = useCallback(() => setMessage(undefined), [])
  const hasGlobalMessage = message !== undefined

  return {
    message,
    setGlobalMessage,
    resetGlobalMessage,
    hasGlobalMessage,
  }
}

const GlobalMessage = createContainer(useGlobalMessage)
export default GlobalMessage
