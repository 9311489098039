import classNames from 'classnames'
import { HtmlHTMLAttributes } from 'react'

const Spinner = ({ className = 'text-orange-500' }) => (
  <div
    className={classNames(
      'animate-spin-fast h-6 w-6 m-2 rounded-full border-2 border-transparent border-solid border-l-current border-t-current',
      className,
    )}
  ></div>
)

const CenterSpinner = (props: HtmlHTMLAttributes<HTMLDivElement>) => (
  <div className="flex justify-center align-center w-full print:hidden">
    <Spinner {...props} />
  </div>
)

export const SmallSpinner = ({ className = 'text-orange-500' }) => (
  <div
    className={classNames(
      'animate-spin-fast rounded-full border-2 border-transparent border-solid border-l-current border-t-current',
      className,
    )}
  ></div>
)

export default CenterSpinner
