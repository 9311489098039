const configuration = {
  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: process.env.REACT_APP_AUTH_UI_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}/logged-in`,
    redirectSignOut: `${window.location.origin}/logged-out`,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
}

export default configuration
