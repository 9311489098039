interface MessageDetail extends Event {
  detail?: string
}
export class GlobalMessageService {
  static onMessage(setMessage: (e) => void, currentMessage?: string) {
    window.addEventListener('global-message', (e: MessageDetail) => {
      if (e?.detail !== currentMessage) {
        setMessage(e?.detail)
      }
    })
  }

  static sendMessage(message: string) {
    window.dispatchEvent(new CustomEvent('global-message', { detail: message }))
  }
}
