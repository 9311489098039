import { ComponentType, Fragment, ReactElement, ReactNode } from 'react'
import {
  MutationObserverSuccessResult,
  QueryObserverSuccessResult,
  UseMutationResult,
  UseQueryResult,
} from 'react-query'

import { ErrorResponse } from 'src/api'

import CenterSpinner from './CenterSpinner'
import ErrorText from './ErrorText'

export default function LoaderWrapper<T>({
  query,
  children,
  messageWrapper,
  hideWhenIdle,
}: {
  query: UseMutationResult<unknown, ErrorResponse, T>
  children: ((query: MutationObserverSuccessResult<unknown, T, ErrorResponse>) => ReactNode) | ReactNode
  messageWrapper?: ComponentType
  hideWhenIdle?: boolean
}): ReactElement

export default function LoaderWrapper<T>({
  query,
  children,
  messageWrapper,
  hideWhenIdle,
}: {
  query: UseQueryResult<T, ErrorResponse>
  children: ((query: QueryObserverSuccessResult<T, ErrorResponse>) => ReactNode) | ReactNode
  messageWrapper?: ComponentType
  hideWhenIdle?: boolean
}): ReactElement

export default function LoaderWrapper<T>({
  query,
  children,
  messageWrapper,
  hideWhenIdle = false,
}: {
  query: UseQueryResult<T, ErrorResponse> | UseMutationResult<unknown, ErrorResponse, T>
  children: ((query: any) => ReactNode) | ReactNode
  messageWrapper?: ComponentType
  hideWhenIdle?: boolean
}): ReactElement {
  const Wrapper = messageWrapper || Fragment
  return query.isLoading ? (
    <Wrapper>
      <CenterSpinner />
    </Wrapper>
  ) : query.isError ? (
    <Wrapper>
      <ErrorText error={query.error} />
    </Wrapper>
  ) : query.isSuccess || !hideWhenIdle ? (
    typeof children === 'function' ? (
      <>{children(query)}</>
    ) : (
      <>{children}</>
    )
  ) : (
    <></>
  )
}
